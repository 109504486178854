@import 'src/app/styles/colors';

.sidebar-item {
    position: relative;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    padding-left: 1.2rem;

    &:hover {
        color: $secondary-blue;
    }
}

.sidebar-item-icon {
    height: 4rem;
    width: 1rem;
    display: flex;
    align-items: center;
    color: white;

    &:hover {
        color: $secondary-blue;
    }
}

.icon-wrapper {
    display: flex;
    gap: 1.5rem;
    align-items: center;
    width: 100%;
    transition: 0.3s;

    &:hover {
        color: $secondary-blue;
    }
}

.routed {
    background-color: #3dcbc8;
}
