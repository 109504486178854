@import url('https://fonts.googleapis.com/css2?family=Roboto+Mono:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import '~bootstrap/scss/bootstrap';
@import './colors';
@import './variables';

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
}

html {
    width: 100%;
    overflow: hidden;
}

body {
    font-family: $font-family;
    font-size: $font-m;
    color: $app-color-primary;
}

.app-main {
    min-height: 100vh;
    width: 100vw;
    background-color: $general-white;
}

.content-page {
    display: flex;
    width: 100%;
}

.page-container {
    flex-grow: 1;
    height: calc(100vh - $appbar-height);
    overflow-y: auto;
    flex-shrink: 100;
    margin-bottom: 100px;
    background: $primary-blue-white;
}

.page-wrapper {
    max-width: 2560px;
    margin: auto;
}
