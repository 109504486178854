@import 'src/app/styles/colors';

.app-link {
    color: $general-white;
}

.white {
    color: $general-white;
    text-decoration: none;

    &:hover {
        color: $general-light-grey;
    }
}

.red {
    color: $accent-bright-red;
    text-decoration: none;
}

.standard {
    color: #4B6687;

    &:hover {
        color: #2C405A;
    }
}

.clear {
    text-decoration: none;
    color: #2c405a;;

    &:hover {
        color: #4b6687;
    }
}

.azure {
    text-decoration: none;
    color: #40a2b5;

    &:hover {
        color: #0c5766;
    }
}
