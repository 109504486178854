@import 'src/app/styles/index';

.sidebar {
    height: $sidebar-height;
    width: $sidebar-width;
    background: $primary-navy-blue;
    position: relative;
    transition: width 0.5s;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-top: 1px solid $secondary-dark-aquamarine;
}

.open {
    width: $sidebar-width-open;
    transition: width 0.5s;
}

.sidebar-items {
    width: 100%;
}
