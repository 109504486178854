$font-family: Roboto, system-ui, -apple-system, 'Segoe UI', 'Helvetica Neue',
    'Noto Sans', 'Liberation Sans', Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji', 'Segoe UI Symbol', 'Noto Color Emoji';

/* font sizes */
$font-xss: 1rem * 0.5;
$font-xs: 1rem * 0.75;
$font-m: 1rem;
$font-l: 1rem * 1.25;
$font-xl: 1rem * 1.5;
$font-xxl: 1rem * 1.75;

/* z-indexes */
$sidebar-z-index: 1000;

/* appbar sizes */
$appbar-height: 48px;
$appbar-padding: 1rem;

/* sidebar sizes */
$sidebar-height: calc(100vh - $appbar-height);
$sidebar-width-open: 17.55rem;
$sidebar-width: 3.55rem;
