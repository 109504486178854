$general-black: #000;
$general-dark-grey: #6a6b6d;
$general-middle-grey: #8e9094;
$general-light-grey: #cfd4d9;
$general-white-grey: #eeeff0;
$general-grey-border: #e1e1e1;
$general-white: #fff;

$primary-navy-blue: #2c405a;
$primary-grey-blue: #4b6687;
$primary-blue: #7088aa;
$primary-light-blue: #7088aa;
$primary-blue-white: #e5eeff;

$secondary-dark-aquamarine: #2d5962;
$secondary-aquamarine: #397b8e;
$secondary-middle-blue: #408fa7;
$secondary-light-blue: #5ca6bc;
$secondary-blue: #35b7de;
$secondary-blue-white: #aee2f1;

$accent-dark-green: #1c9477;
$accent-green: #1eb28e;
$accent-light-green: #6fd1b9;

$accent-dark-red: #c14242;
$accent-bright-red: #e95050;
$accent-light-red: #f48a8a;

$app-color-light: #30527aff;

.app-bg-light {
    background-color: $app-color-light;
}

$app-color-dark: #2c405aff;

.app-bg-dark {
    background-color: $app-color-dark;
}

$app-color-primary: #324766ff;

.app-color-primary {
    color: #324766ff;
}

$app-color-secondary: #3f536eff;

.app-color-secondary {
    color: $app-color-secondary;
}

$app-color-tertiary: #8dabc4ff;

.app-color-tertiary {
    color: $app-color-tertiary;
}

$app-color-border: #c5dbeaff;

.app-color-border {
    border-color: #c5dbeaff;
}
