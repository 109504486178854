@import 'src/app/styles/variables';

$btn-apply-color: #4b6687;
$btn-apply-hover: #2c405a;
$btn-apply-disable: #a7b9d5;
$btn-apply-active-border: rgba(75, 102, 135, 0.5);

$btn-delete-color: #e95050;
$btn-delete-hover: #c14242;
$btn-delete-disable: #f48a8a;
$btn-delete-active-border: rgba(233, 80, 80, 0.5);

$btn-reset-color: #40a2b5;
$btn-reset-hover: #397b8e;
$btn-reset-disable: #40a2b5;
$btn-reset-active-border: rgba(64, 162, 181, 0.5);

$btn-cancel-color: #8e9094;
$btn-cancel-hover: #6a6b6d;
$btn-cancel-disable: #cfd4d9;
$btn-cancel-active-border: rgba(142, 144, 148, 0.5);

$btn-add-color: #1eb28e;
$btn-add-hover: #1c9477;
$btn-add-disable: #6fd1b9;
$btn-add-active-border: rgba(30, 178, 142, 0.5);

.button {
    cursor: pointer;
    padding: 6px 20px;
    color: #fff;
    border-radius: 4px;
    border: 2px solid transparent;
}

.btn-clear {
    border: none;
    background: none;
    padding: 0;
    margin: 0;
    &:hover {
        color: red;
    }
}

.btn-apply {
    background: $btn-apply-color;
    &:hover {
        background: $btn-apply-hover;
    }
    &:focus {
        background: $btn-apply-hover;
        outline: 2px solid $btn-apply-active-border;
    }
    &:active {
        background: $btn-apply-color;
        border: 2px solid $btn-apply-active-border;
    }
    &:disabled {
        background: $btn-apply-disable;
        border: 2px solid transparent;
    }
}

.btn-delete {
    background: $btn-delete-color;
    &:hover {
        background: $btn-delete-hover;
    }
    &:focus {
        background: $btn-delete-hover;
        outline: 2px solid $btn-delete-active-border;
    }
    &:active {
        background: $btn-delete-color;
        border: 2px solid $btn-delete-active-border;
    }
    &:disabled {
        background: $btn-delete-disable;
        border: 2px solid transparent;
    }
}

.btn-reset {
    background: $btn-reset-color;
    padding: 6px 20px 6px 20px;
    &:hover {
        background: $btn-reset-hover;
    }
    &:focus {
        background: $btn-reset-hover;
        outline: 2px solid $btn-reset-active-border;
    }
    &:active {
        background: $btn-reset-hover;
        border: 2px solid $btn-reset-active-border;
    }
    &:disabled {
        background: $btn-reset-disable;
        border: 2px solid transparent;
    }
}

.btn-cancel {
    background: $btn-cancel-color;
    &:hover {
        background: $btn-cancel-hover;
    }
    &:focus {
        background: $btn-cancel-hover;
        outline: 2px solid $btn-cancel-active-border;
    }
    &:active {
        background: #8e9094;
        border: 2px solid $btn-cancel-active-border;
    }
    &:disabled {
        background: $btn-cancel-disable;
        border: 2px solid transparent;
    }
}

.btn-add {
    width: fit-content;
    background: $btn-add-color;
    color: #fff;
    border: 2px solid transparent;
    transition: 0.3s;
    &:hover {
        background: $btn-add-hover;
    }
    &:focus {
        background: $btn-add-hover;
        outline: 2px solid $btn-add-active-border;
    }
    &:active {
        background: $btn-add-hover;
        border: 2px solid $btn-add-active-border;
    }
    &:disabled {
        background: $btn-add-disable;
    }
}

.disabled {
    opacity: 50%;
}

.btn-xxs {
    font-size: $font-xss;
}
.btn-xs {
    font-size: $font-xs;
}
.btn-m {
    font-size: $font-m;
}
.btn-l {
    font-size: $font-l;
}
.btn-xl {
    font-size: $font-xl;
}
.btn-xxl {
    font-size: $font-xxl;
}
