@import 'src/app/styles/index';

.appbar {
    width: 100%;
    height: $appbar-height;
    background: $primary-navy-blue;
    display: flex;
    align-items: center;
}

.sidebar-toggle {
    display: flex;
    gap: 1.3rem;
    align-items: center;
    font-size: 1.4rem;
    width: $sidebar-width;
    padding-left: calc($appbar-padding + 0.2rem);
    padding-right: $appbar-padding;

    &:hover {
        color: $secondary-blue;
    }
}

.menu {
    font-size: 1rem;
    text-align: center;
}

.main-logo {
    margin: auto;
}
