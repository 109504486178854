.custom-chip {
    background-color: rgba(206, 212, 218, 0.35);
    display: inline-block;
    font-family: "Segoe UI", sans-serif;
    padding: 0 6px;
    min-height: 2rem;
    min-width: 2rem;
    line-height: 2rem;
    border: 1px solid rgba(206, 212, 218, 0.35);
    border-radius: 4px;
    text-align: center;
    cursor: pointer;

    &:hover {
        background-color: rgba(225, 231, 237, 0.35);

        .delete-btn {
            color: rgba(241, 152, 152, 0.95);
        }
    }

    &:active {
        background-color: rgba(236, 239, 242, 0.86)
    }
}

.delete-btn {
    padding: 0 4px 0 6px;
    color: rgba(103, 102, 102, 0.5);

    &:hover {
        color: rgba(241, 152, 152, 0.95);
    }
}
